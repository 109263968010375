// https://www.joomlashack.com/blog/tutorials/center-and-align-items-in-css-grid/
// https://alligator.io/css/align-justify/

.container
  margin: 0 auto 1rem auto
  padding: 0 1rem

.container-tripple
  max-width: 66rem

.container-double
  max-width: 44rem

.container-single
  max-width: 22rem

@supports (display: grid)
  .grid-auto
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr))
    grid-template-rows: auto
    grid-gap: 1rem
  
  .grid-auto > *
    max-width: unset

  .grid-auto > * + *
    margin-top: unset

  .grid-one, .grid-two, .grid-three
    display: grid
    grid-template-columns: repeat(1, 1fr)
    grid-template-rows: auto
    grid-gap: 1rem
  
  .grid-one > *, .grid-two > *, .grid-three > *
    max-width: unset

  .grid-one > * + *, .grid-two > * + *, .grid-three > * + *
    margin-top: unset

  .grid-header
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr))
    grid-gap: 1rem

  .grid-header > *
    max-width: unset

  .grid-header > * + *
    margin-top: unset

  .grid-lesson
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr))
    grid-gap: 1rem

  .grid-lesson > *
    max-width: unset

  .grid-lesson > * + *
    margin-top: unset

  @media screen and (min-width: 760px)
    .grid-header
      display: grid
      grid-template-columns: 10fr 2fr
      grid-template-rows: auto
      grid-template-areas: "breadcrumbs logout"
      grid-gap: 1rem

      .grid-header-breadcrumbs
        grid-area: breadcrumbs
        position: relative

      .grid-header-menu
        grid-area: logout

    .grid-one
      display: grid
      grid-template-columns: repeat(1, 1fr)
      grid-template-rows: auto
      grid-gap: 1rem

    .grid-two
      display: grid
      grid-template-columns: repeat(2, 1fr)
      grid-template-rows: auto
      grid-gap: 1rem

    .grid-three
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-template-rows: auto
      grid-gap: 1rem

    .grid-lesson
      display: grid
      grid-template-columns: 2fr 5fr
      grid-template-rows: auto
      grid-template-areas: "sidebar main"
      grid-gap: 1rem

      .grid-lesson-main
        grid-area: main
        position: relative

      .grid-lesson-sidebar
        grid-area: sidebar

@supports not (display: grid)
  .grid-auto
    --auto-grid-min-size: 16rem

  .grid-auto > *
    max-width: 400px

  .grid-auto > * + *
    margin-top: 1rem

  .grid-lesson
    --auto-grid-min-size: 16rem

  .grid-lesson > *
    max-width: 900px

  .grid-lesson > * + *
    margin-top: 1rem