.card
  font-size: #{$learn_base_font_size}
  background: #FFF
  margin: 0 auto 1rem auto
  border: none
  border-radius: 0.3em
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2)
  min-width: 100%

  > :first-child
    border-radius: 0.3em 0.3em 0 0

  .image
    position: relative
    display: block
    -webkit-box-flex: 0
    -ms-flex: 0 0 0 0
    flex: 0 0 0 0
    padding: 0
    background: rgba(0,0,0,1)

    img
      display: block
      width: 100%
      height: auto
      border-radius: inherit

  .header, .kotive-task-header
    font-weight: 400
    padding: 15px 15px 15px 15px
    border-bottom: 1px solid #EFEFEF

  .content, .kotive-task-body
    font-size: 0.85rem
    font-weight: 300
    padding: 15px 15px 15px 15px

  .footer, .kotive-task-footer
    font-weight: 300
    padding: 10px 15px 15px 15px
    border-top: 1px solid #EFEFEF

.card-course
  margin: 0 auto 0 auto

  .image
    min-height: 0.25rem
    max-height: 12rem
    background: #D9C6BA
    overflow: hidden

  .content
    h3
      font-weight: 400
      font-size: 1.1rem
      margin-bottom: 0.5rem

    .author-name
      font-weight: 400
      font-size: 0.8rem

    .author-desc
      font-style: italic
      font-size: 0.8rem

    .author-photo
      border-radius: 500rem
      max-width: 48px

  .footer
    max-height: 2.25rem
    // position: relative
    //bottom: 0

.card-lesson
  display: grid
  grid-template-columns: 100px minmax(100px, 1fr)
  grid-gap: 1rem
  margin: 0 auto 0 auto
  cursor: pointer

  &:hover
    box-shadow: 0px 1px 3px rgba(82, 89, 105, 1)
    margin: 0 auto 0 auto

  > :first-child
    border-radius: 0.3em 0 0 0.3em

  .visual
    grid-area: auto
    height: 100px
    text-align: center
    background: #EFEFEF

  .info
    text-align: left
    height: 80px
    overflow-y: hidden
    padding: 10px 10px 10px 0
