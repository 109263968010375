.clearfix:after
  content: ""
  display: block
  clear: both

.hidden
  display: none

.text-muted
  color: #777777

.fluid
  width: 100% !important

.text-center
  text-align: center

.pull-left
  float: left !important

.pull-right
  float: right !important

hr
  border: 1px solid #EEE
  border-width: 1px 0px 0px
  height: 0px
  box-sizing: content-box
  width: 100%
  margin: 0

.yellow-strip
  font-weight: 300
  line-height: 1.42857
  font-size: 85%
  color: #777
  background-color: #FCF8E3
  padding: 0.2em 0.5em
  margin-left: 0.7em
  margin-right: 0.7em

.orange-strip
  font-weight: 300
  line-height: 1.42857
  padding: 0.8em 0.5em 0.8em 0.5em
  background-color: #ff640712
  margin-left: 0.7em
  margin-right: 0.7em
  border: 3px solid #ff6407
  border-radius: 0.3em
  text-align: center

.ellipsis
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

// disable printing of lesson
@media print
  body
    display: none !important
    visibility: hidden