.breadcrumbs
  font-size: 0.85rem
  margin-left: 1rem

.menu-links
  font-size: 0.85rem
  margin-left: 1rem
  margin-right: 1rem
  width: 100%
  float: right

  a
    margin-right: 0.5rem

@media screen and (min-width: 760px)
  .menu-links
    float: right
    min-width: 190px

#footer
  margin-top: 2rem
  padding-top: 1rem
  font-size: 0.85rem
  font-style: italic
  text-align: center