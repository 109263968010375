.ui.form
  font-size: #{$learn_base_form_font_size}

  .field
    margin: 0 0 1em 0
    clear: both

  .kotive-field-input.field
    > label
      display: inline-block
      max-width: 100%
      margin-bottom: 5px
      font-weight: 400
      color: #{$kotive_darkblue}

    .optional
      font-weight: 300
      line-height: 1.42857
      font-size: .85em
      color: #777
      background-color: #{$kotive_optional}
      padding: 0.2em 0.5em
      margin-left: 0.7em

    .marks
      font-weight: 400
      line-height: 1.42857
      font-size: .85em
      color: #777
      background-color: #{$kotive_optional}
      border-radius: 0.3em
      padding: 0.2em 0.5em
      margin-left: 0.7em
      white-space: nowrap

      &.marks-correct
        background: radial-gradient(circle farthest-corner at right bottom, #{$grad_correct_start} 10%, #{$grad_correct_end} 80%)
        color: #FFF

      &.marks-incorrect
        background: radial-gradient(circle farthest-corner at right bottom, #{$grad_incorrect_start} 10%, #{$grad_incorrect_end} 80%)
        color: #FFF

    .help-block
      display: block
      font-size: 12px
      margin: -5px 1px 2px
      color: #737373
      font-weight: 300

    .invalid-field-message
      display: block
      font-size: 12px
      margin: 0px 10px 0px 10px
      font-weight: 400
      color: #8A6D3B
      background-color: #{$kotive_optional}
      padding: 0.2em 0.5em

    .checkbox-option, .radio-option
      position: relative
      display: block
      min-height: 20px
      margin-top: 5px
      margin-bottom: 0px
      font-weight: 300
      cursor: pointer

    .checkbox-option-inline, .radio-option-inline
      display: inline-block
      padding-right: 20px
      margin-bottom: 0px
      vertical-align: middle
      font-weight: 300
      cursor: pointer

    .checkbox-option-desired, .radio-option-desired
      border: 2px dashed #{$grad_correct_start}
      border-radius: 0.5rem
      padding: 5px 0px

    .checkbox-option-correct, .radio-option-correct
      background: radial-gradient(circle farthest-corner at right bottom, #{$grad_correct_start} 10%, #{$grad_correct_end} 80%)
      border: 0px
      border-radius: 0.5rem
      padding: 5px 5px 5px 0px
      color: #fff
      font-weight: 400

    .checkbox-option-incorrect, .radio-option-incorrect
      background: radial-gradient(circle farthest-corner at right bottom, #{$grad_incorrect_start} 10%, #{$grad_incorrect_end} 80%)
      border-radius: 0.5rem
      padding: 5px 0px
      color: #fff
      font-weight: 400

    input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="time"], input[type="week"], textarea
      color: #{$kotive_darkblue}
      font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif
      font-weight: 300
      width: (100%-9%)
      margin: 0 auto 0 auto
      padding: .67857143em 1em
      vertical-align: top
      outline: none
      -webkit-appearance: none
      tap-highlight-color: hsla(0,0%,100%,0)
      line-height: 1.21428571em
      font-size: 1em
      background: #fff
      border: 1px solid rgba(34,36,38,.15)
      border-radius: 0.3em
      box-shadow: inset 0 0 0 0 transparent
      transition: color .1s ease,border-color .1s ease

      &:focus
        border-color: #{$const_linkblue_lighter}
        outline: 0px none

    input[type="checkbox"]
      vertical-align: middle

    select
      display: block
      width: 100%
      height: 34px
      padding: 6px 12px
      font-size: 14px
      font-weight: 300
      line-height: 1.42857
      color: #555
      background: #FFF
      border: 1px solid #CCC
      border-radius: 0.3em

    select:focus
      border-color: #{$const_linkblue_lighter}
      outline: 0px none

    select[disabled], input[readonly], textarea[readonly]
      background: #{$kotive_mediumgray}

    textarea
      height: auto

    h1
      font-size: 2em
      font-weight: 300
      margin: 0px

    .kotive-field-paragraph
      padding: 0
      border: none
      background: none
      font-weight: 300
      font-size: 14px
      color: #{$kotive_darkblue}
      overflow: auto
      width: 100%
      white-space: pre-wrap
      white-space: -moz-pre-wrap !important
      white-space: -pre-wrap
      white-space: -o-pre-wrap
      word-wrap: break-word

      // disable copy&paste
      -webkit-user-select: none
      -webkit-touch-callout: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

      h1
        font-size: 1.75em
        font-weight: 300
        margin: 0.5em 0 0 0

      h2
        font-size: 1.5em
        font-weight: 300
        margin: 0.5em 0 0 0

      h3
        font-size: 1.25em
        font-weight: 300
        margin: 0.5em 0 0 0

      ul
        padding-left: 1.5rem
      
      ol
        padding-left: 1.5rem

      blockquote
        padding: 1rem
        margin: 1rem
        border-left: 1px solid #{$const_lightgray}
        background: #{$const_cream}

  .kotive-field-input.field.kotive-field-input-incomplete
    background: #{$kotive_optional}
    color: #8A6D3B
    padding: 10px
    margin-bottom: 20px
    border: 1px solid #{$kotive_optional}
    border-radius: 4px

  .kotive-field-input.field.kotive-field-input-nudge
    background: #f4f8f9
    color: #8A6D3B
    padding: 10px
    border: 1px solid #f4f8f9
    border-radius: 4px

  .kotive-validation-message
    border-color: #FAEBCC
    color: #8A6D3B
    padding: 15px
    margin-bottom: 20px
    border: 4px solid #{$kotive_optional}
    border-radius: 4px

  .filepicker-btn-disabled
    margin-left: 10px
    margin-bottom: 10px
    font-weight: 300 !important
    border: 1px solid transparent
    border-color: #{$kotive_mediumgray}
    background-color: #{$kotive_lightgray}
    color: #{$kotive_darkblue}
    cursor: not-allowed

/* better differentiation between fields */
.ui.form.kotive-published-task-form
  .kotive-field-input.field
    margin-bottom: 1.5rem

    > label:first-child
      font-size: 1rem

/* buttons */
button, input[type=button], input[type=reset], input[type=submit], .btn
  position: relative
  display: inline-block
  margin-bottom: 0px
  padding: 7px 15px
  font-weight: 300
  text-align: center
  vertical-align: middle
  cursor: pointer
  background-image: none
  border: 1px solid transparent
  white-space: nowrap
  font-size: 1em
  line-height: 1.42857
  border-radius: .35em
  -moz-user-select: none
  color: #FFF
  background-color: #81E1C6
  border-color: #74DDC0
  border-bottom: 2px solid #30CCA0

  &.large
    font-size: 1.15em
    padding: 9px 15px

  &.disabled
    cursor: default
    opacity: .45 !important
    background-image: none !important
    box-shadow: none !important
    pointer-events: none !important

  &.loading
    color: transparent !important
    cursor: default
    padding: 8.7px 15px

    &:before
      position: absolute
      content: ''
      top: 50%
      left: 50%
      margin: -0.64285714em 0em 0em -0.64285714em
      width: 1rem
      height: 1rem
      border-radius: 500rem
      border: 0.2em solid rgba(0, 0, 0, 0.15)
    
    &:after
      position: absolute
      content: ''
      top: 50%
      left: 50%
      margin: -0.64285714em 0em 0em -0.64285714em
      width: 1rem
      height: 1rem
      border-radius: 500rem
      border-width: 0.2em
      border-style: solid
      border-color: #FFFFFF transparent transparent
      -webkit-box-shadow: 0px 0px 0px 1px transparent
      box-shadow: 0px 0px 0px 1px transparent
      -webkit-animation: loading-spin 0.6s linear
      animation: loading-spin 0.6s linear
      -webkit-animation-iteration-count: infinite
      animation-iteration-count: infinite

button:hover, input[type=button]:hover, input[type=reset]:hover, input[type=submit]:hover, .btn:hover
  color: #FFF
  background-color: #58D7B3
  border-color: #3BD0A6
