.page-header
  font-size: 2rem
  font-weight: 300
  margin: 0 1rem 1rem 1rem

.page-subheader
  font-size: 1.75rem
  font-weight: 300
  margin: 0 1rem 1rem 1rem

.lessonMenu
  padding: 0 !important

  ul
    list-style: none

    li
      padding: 1rem
      border-bottom: 1px solid #{$const_cream}

      &.completed
        position: relative

        :after
          content: ''
          position: absolute
          top: 1rem
          left: 96%
          border-color: #{$const_retro_green}
          border-style: solid
          border-width: 0 0.25em 0.25em 0
          height: 1.3rem
          margin-top: -0.75rem
          transform: rotate(45deg)
          width: 0.5rem

      &.current
        background: #{$const_cream}
        padding: 1rem
        font-weight: 300
        border-bottom: 1px solid #e3daca 

    li:last-child
      border-bottom: none