.loading
  color: #{$const_darkblue}
  font-size: 0.85rem
  font-style: normal
  text-align: center
  padding-top: 2rem
  width: auto !important
  height: auto !important
  //position: absolute
  //top: 5%
  //left: 50%
  margin: 0
  z-index: 1000
  cursor: default

  &:before
    position: absolute
    content: ''
    top: 1rem
    left: 50%
    margin: -0.64285714em 0em 0em -0.64285714em
    width: 1rem
    height: 1rem
    border-radius: 500rem
    border: 0.2em solid rgba(0, 0, 0, 0.15)
  
  &:after
    position: absolute
    content: ''
    top: 1rem
    left: 50%
    margin: -0.64285714em 0em 0em -0.64285714em
    width: 1rem
    height: 1rem
    border-radius: 500rem
    border-width: 0.2em
    border-style: solid
    border-color: #FFFFFF transparent transparent
    -webkit-box-shadow: 0px 0px 0px 1px transparent
    box-shadow: 0px 0px 0px 1px transparent
    -webkit-animation: loading-spin 0.6s linear
    animation: loading-spin 0.6s linear
    -webkit-animation-iteration-count: infinite
    animation-iteration-count: infinite

@-ms-keyframes loading-spin
  from
    -ms-transform: rotate(0deg)
  to
    -ms-transform: rotate(359deg)

@-moz-keyframes loading-spin
  from
    -moz-transform: rotate(0deg)
  to
    -moz-transform: rotate(359deg)

@-webkit-keyframes loading-spin
  from
    -webkit-transform: rotate(0deg)
  to
    -webkit-transform: rotate(359deg)

@keyframes loading-spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)