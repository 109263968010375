body
  background: #{$const_cream}
  color: #{$const_darkblue}
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif
  font-size: #{$learn_base_font_size}
  line-height: 1.42857
  margin: 0px

h1, h2, h3, p
  margin: 0 0 1em 0

strong
  font-weight: 400

em
  font-style: italic

a
  background-color: transparent
  color: #{$const_linkblue}
  text-decoration: none
  border-bottom: 2px solid #{$const_linkblue}
  word-wrap: break-word
  padding: 1px 3px

  &:hover
    color: #FFF
    background-color: #{$const_linkblue}

  &.back
    border-bottom: none
    font-weight: 400

small
  font-size: #{$learn_base_small_font_size}

#canvas
  margin-top: 1rem
  margin-bottom: 1rem